

.icon_button_anim  {
    animation: icon_button_animation 1s linear infinite;
}

/* 
.icon_button_anim .MuiSvgIcon-root.feather {
  animation: icon_button_animation_svg 5s linear infinite;
} */

@keyframes icon_button_animation {
0% {
    /* transform: scale(1.06); */
    /* background-color: #ff5722; */
    background-color: #fff;
    color: #ff5722;
    /* opacity: 0.4; */
  }
  50% {
    /* transform: scale(1.06); */
    background-color: #ff5722;
    color: #ffffff;
    /* opacity: 0.4; */
  }
  100% {
    /* transform: scale(1.06); */
    background-color: #fff;
    color: #ff5722;
    /* opacity: 0.4; */
}
}
@keyframes icon_button_animation_svg {
0% {
    /* transform: scale(1.06); */
    /* background-color: #ff5722; */
    color: #ff5722;
    /* opacity: 0.4; */
  }
  50% {
    /* transform: scale(1.06); */
    color: #fff;
    /* opacity: 0.4; */
  }
  100% {
    /* transform: scale(1.06); */
    color: #ff5722;
    /* opacity: 0.4; */
}
}

.button_anim {
    animation: button_animation 2s linear infinite;
}

@keyframes button_animation {
0% {
    /* transform: scale(1.06); */
    /* background-color: #ff5722; */
    /* background-color: rgb(255, 86, 34); */
    /* opacity: 0.4; */
}
50% {
    transform: scale(1.02);
    /* background-color: rgb(255, 63, 34); */
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.3);
    /* opacity: 0.4; */
}
100% {
    /* transform: scale(1.06); */
    /* background-color: rgb(255, 86, 34); */
    /* opacity: 0.4; */
}
}

.label_highlight {
    border: 1px solid;
    border-radius: 6px;
}


.center_position {
    transform-origin: center !important;
}

#tooltip {
    z-index: 1000;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#tooltip[x-placement^='top'] > #arrow {
    bottom: -4px;
  }
  
  #tooltip[x-placement^='bottom'] > #arrow {
    top: -4px;
  }
  
  #tooltip[x-placement^='left'] > #arrow {
    right: -4px;
  }
  
  #tooltip[x-placement^='right'] > #arrow {
    left: -4px;
  }

  .ch-popper-close {
    border: 1px solid #1b5d86;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -9px;
    top: -9px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    background-color: #fff;
    color: #206D9C;

    transition: all .4s;
  }

  .ch-popper-close:hover {
    color: #fff;
    background-color: #206D9C;
    cursor: pointer;
  }
