@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@200;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

html,body{
  background-color: #ffffff;
  margin: 0;
  padding: 0;
}

:-webkit-full-screen {
  width: 100%;
  height: 100%;
}

code {
  font-family: 'DM Mono';
}