.template-preview {
    background-color: #fff !important;
    padding: 0 !important;
}


.template-preview.titlebar {
    display: none;
}

.template-preview #Iframe {
    width: 95%;
    border: 1px solid #0006;
    box-shadow: #0006 -1px 0px 12px;
    height: calc(100vh);
    box-sizing: border-box;
    position: relative;
    display: block;
    margin: 10px auto;
    overflow-x: hidden;
}
