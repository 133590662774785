[data-field="token"] .copy:hover {
    cursor: copy;
}
[data-field="ip"] .copy:hover {
    cursor: copy;
}


[data-field="agent"]:hover {
    cursor: pointer;
}
[data-field="ip_data"][data-value]:hover {
    cursor: pointer;
}

[data-field="ip"] p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



@media (max-width: 1542px) {
    [data-field="ip"] p {
        max-width: 130px;
    }
}
@media (max-width: 1440px) {
    [data-field="ip"] p {
        max-width: 190px;
    }
}
@media (max-width: 1340px) {
    [data-field="ip"] p {
        max-width: 100px;
    }
}
@media (max-width: 1240px) {
    [data-field="ip"] p {
        max-width: 250px;
    }
}


@media (max-width: 1157px) {
    [data-field="ip"] p {
        max-width: 290px;
    }
}

@media (max-width: 1070px) {
    [data-field="ip"] p {
        max-width: 120px;
    }
}